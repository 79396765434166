<template>
  <div>
    <div class="synopsis-page">
      <img src="../../assets/images/news/news-ban.png" />
      <div class="section">
        <div class="left-nav">
          <router-link to="/">首页</router-link>
          <i>&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;</i>
          <router-link to="presscenter">新闻中心</router-link>
          <i>&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;</i>

          <router-link v-if="id == '6'" to="domesticWaste">业界新闻</router-link>
          <router-link v-if="id == '7'" to="domesticWaste">业界新闻</router-link>
          <router-link v-if="id == '8'" to="domesticWaste">业界新闻</router-link>
          <router-link v-if="id == '9'" to="domesticWaste">业界新闻</router-link>
          <router-link v-if="id == '10'" to="domesticWaste">业界新闻</router-link>
          <router-link v-if="id == '11'" to="domesticWaste">业界新闻</router-link>
          <router-link v-if="id == '1'" to="corporateNews">企业新闻</router-link>
          <router-link v-if="id == '2'" to="corporateNews">企业新闻</router-link>
          <router-link v-if="id == '3'" to="corporateNews">企业新闻</router-link>
          <router-link v-if="id == '4'" to="corporateNews">企业新闻</router-link>
          <router-link v-if="id == '5'" to="corporateNews">企业新闻</router-link>
          <router-link v-if="id == '0'" to="corporateNews">企业新闻</router-link>
          <router-link v-if="id == '1001'" to="corporateNews">企业新闻</router-link>
        </div>

        <div class="news-main">
          <div class="section">
            <div class="text-box">
              <div class="synopsis">
                <!-- 年度上新！苏州人才政策申报月历（2024版）来啦→ -->
                <div v-if="id == '1002'">
                  <span style="font-size: 23px; font-weight: bold">
                    年度上新！苏州人才政策申报月历（2024版）来啦→
                  </span>
                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2024-02-29 14:35:15</h5>
                    <!-- <h4>
                      业务展示
                    </h4> -->
                    <p style="font-size: 16px;text-align: center">
                      2024年苏州人才政策申报月历,重磅来袭,快收好。
                    </p>
                    <img style="height: 1350px;width:670px " src="../../assets/images/zigongsi/660.png">
<!--                    3月-->
                    <div> <img style="height: 202px;width:202px " src="../../assets/images/zigongsi/661.png"></div>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                         <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">姑苏创新创业领军人才计划</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">重大创新团队</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">1.最高5000万元项目经费</div>
                        <div style="padding-top: 16px;padding-left: 5rem">2.购房补贴</div>
                        <div style="padding-top: 16px;padding-left: 5rem">3.引才奖励</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">创业领军人才</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">1.最高500万元项目经费</div>
                        <div style="padding-top: 16px;padding-left: 5rem">2.购房补贴</div>
                        <div style="padding-top: 16px;padding-left: 5rem">3.滚动支持</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">创新领军人才</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">1.最高200万元项目经费</div>
                        <div style="padding-top: 16px;padding-left: 5rem">2.购房补贴</div>
                        <div style="padding-top: 16px;padding-left: 5rem">3.引才奖励</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市科学技术局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65233005</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">顶尖人才（团队）“一人一策”</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">团队</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">可直接立项为姑苏重大创新团队（最高5000万元项目经费）或临床医学专家团队（最高1500万元项目经费），且可突破原政策支持限额，给予上不封顶的支持。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">个人</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem"><span style="color: #00056E">1.顶尖人才：</span>最高500万元购房贴，最高100万元贡献奖励。</div>
                        <div style="padding-top: 16px;padding-left: 5rem"><span style="color: #00056E">2.核心成员：</span>最高250万元购房贴，最高100万元贡献奖励。</div>
                        <div style="padding-top: 16px;padding-left: 5rem"><span style="color: #00056E">3.青年骨干：</span>直接入选姑苏重点产业紧缺人才，给予最高15万元薪酬补贴。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市科学技术局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65119071</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市委宣传部</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-68617623</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市卫生健康委员会</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-68830175、67512325</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市地方金融监督管理局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-67339630</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
<!--                    4月&ndash;&gt;-->
                    <div> <img style="height: 202px;width:202px " src="../../assets/images/zigongsi/662.png"></div>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">姑苏宣传文化人才计划</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">特聘人才</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">1.最高250万元安家补贴。</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.购买自住住房申请住房公积金贷款，不受缴存时间限制，按缴存基数计算可贷额度，贷款额度至多可放宽至最高限额的4倍。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">姑苏宣传文化创新创业团队</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">给予最高300万元项目资助额度。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">分层培养</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">给予最高15万元一次性奖励和100万元项目资助额度。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市委宣传部</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-68617623</span></h3>

                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">高层次人才子女教育</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">学前、义务教育阶段</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">高层次人才子女入（转）学，可由教育行政部门根据人才实际情况安排定点学校就读；其父母或法定监护人在苏有合法固定住所的，安排在地段公办学校就读；在苏无合法固定住所的，一般根据学额情况，统筹安排公办学校就读。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">高中教育阶段</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">
                          高层次人才子女参加本市中考，录取时不受户籍限制；同等条件下，给予优先录取；如符合加分政策的，同等享受本市户籍居民子女待遇；入学时减免学费。
                          如属于普通高中学段转学的，按与原就读学校同类别、同星级的原则，优先安排四星级或三星级高中就读。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市高层次人才一站式服务中心</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65224600</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>

<!--                    5月-->
                    <div> <img style="height: 202px;width:202px " src="../../assets/images/zigongsi/663.png"></div>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">姑苏体育人才计划</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">人才引进</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">1.最高250万元安家补贴</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.购买自住住房申请住房公积金贷款，不受缴存时间限制，按缴存基数计算可贷额度，贷款额度可放宽至最高限额的4倍。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">分层培养</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">给予最高15万元一次性奖励和5万元培养资助额度。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市体育局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65235343</span></h3>

                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">江苏省海外高层次人才居住证持证人贡献奖励</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">奖励标准</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">奖励金额为持证人上年度在当地税务部门所缴工薪收入个人所得税额的40%，在苏州大市范围内历年奖励总额累计不超过30万元。</div>
                      </div>

                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市人力资源和社会保障局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512- 69820113</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>

<!--                    6月&ndash;&gt;-->
                    <div> <img style="height: 202px;width:202px " src="../../assets/images/zigongsi/664.png"></div>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">姑苏知识产权人才计划</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">人才引进</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">1.最高250万元安家补贴</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.政策支持：购买首套自住住房申请住房公积金贷款，不受缴存时间限制，可按公积金缴存基数计算贷款额度，贷款额度可放宽至最高限额的4倍。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">人才培养</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">给予最高20万元的项目资助。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市市场监督管理局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-69821306</span></h3>

                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">企业博士后创新体系建设资助</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">建站资助</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经批准设立的博士后科研工作站给予60万元建站资助，获批的省博士后创新实践基地给予20万元建站资助，鼓励创新实践基地升格为博士后工作站，升格后追加40万元建站资助。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">生活补贴</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">对进站的博士后，给予5000元/月的生活补贴，两年内最高可予12万元/人。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">科研配套资助</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">博士后在站期间的研究项目获得中国博士后科学基金特别资助和面上资助的，分别给予5万元、2万元科研配套资助。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">安家补贴</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">对出站后留（来）苏企业工作的博士后给予30万元安家补贴。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市人力资源和社会保障局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-69820576</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <!--                    7月&ndash;&gt;-->
                    <div> <img style="height: 202px;width:202px " src="../../assets/images/zigongsi/665.png"></div>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top:1.75rem">姑苏卫生人才计划</div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">人才引进</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">1.最高250万元安家补贴</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.政策支持：购买首套自住住房申请住房公积金贷款，不受缴存时间限制，可按公积金缴存基数计算贷款额度，贷款额度可放宽至最高限额的4倍。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">人才培养</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">给予最高20万元的项目资助。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市市场监督管理局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-69821306</span></h3>

                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">姑苏高技能领军人才计划
                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">资金支持</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">100万元安家补贴。企业申报并入选的，给予最高50万元企业引才补贴。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">政策支持</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem">购买自住住房申请住房公积金贷款，不受缴存时间限制，按缴存基数计算可贷额度，贷款额度可放宽至最高限额的4倍。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市人力资源和社会保障局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-69820096</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">“海鸥计划”（海外柔性引才）
                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">1.在用人单位领取工薪：根据用人单位实际支付计税工薪的一定比例给予引才补贴，单个项目（人才）补贴最高60万元，高端外国专家单个项目（人才）补贴最高100万元。</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.不在用人单位领取工薪：按专家交通费、专家咨询费（讲课费）或专家补贴、专家生活费综合核算补贴经费单个项目（人才）补贴最高30万。</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">3.符合“海鸥计划”申报条件并入选省级以上引进国外技术、管理人才项目计划的外籍专家，直接列入“海鸥计划”给予同比例支持，单个项目（人才）补贴最高60万元。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市科学技术局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65233005</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <!--                    8月&ndash;&gt;-->
                    <div> <img style="height: 202px;width:202px " src="../../assets/images/zigongsi/666.png"></div>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">姑苏重点产业紧缺人才计划
                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">资金支持</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">最高给予15万元薪酬补贴。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">政策支持</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem ;line-height: 1.5">1.购买自住住房申请住房公积金贷款，不受缴存时间限制，按缴存基数计算可贷额度，贷款额度可放宽至最高限额的2倍。</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.非户籍子女就读义务教育阶段学校，享受本市户籍学生待遇。
                        </div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市人力资源和社会保障局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65811070、65811055</span></h3>

                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">市属国有企业引进重点领域
                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">紧缺人才认定和激励</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">1.对符合条件经认定的市属国有企业重点领域紧缺人才，给予最高6万元薪酬补贴。</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.对经市级认定的姑苏重点产业紧缺人才，除享受该市级人才计划相应的资助外，另行给予最高3万元补贴支持。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市政府国资委</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-68615327</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <!--                    9月&ndash;&gt;-->
                    <div> <img style="height: 202px;width:202px " src="../../assets/images/zigongsi/667.png"></div>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">人才乐居工程
                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">乐居购房贴</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">最高800万元购房贴。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">乐居优购房</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem ;line-height: 1.5">建设人才优购房，重点面向指定人才优惠出售。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">乐居优租房</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem ;line-height: 1.5">筹建人才优租房，重点面向指定人才配租，租金低于同类区域市场平均租金。
                        </div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">乐居租房贴</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem ;line-height: 1.5">最高8000元/月租房贴。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">乐居助力贷</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem ;line-height: 1.5">首次使用公积金住房贷款购买首套住房，不受缴存时间限制，按缴存基数计算可贷额度，贷款额度可放宽至最高限额的4倍。
                        </div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市人力资源和社会保障局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-69820113</span></h3>

                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">姑苏教育人才计划

                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">人才引进</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">1.资金支持：最高250万元安家补贴。</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.政策支持：购买自住住房申请住房公积金贷款，不受缴存时间限制，按缴存基数计算可贷额度，贷款额度可放宽至最高限额的4倍。</div>
                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市教育局</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65224787</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top:1.75rem">高层次学术活动资助办法
                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">政策支持</h3>
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">高端专业学术会议最高给予100万元资助，其他学术交流活动最高给予20万元资助。</div>

                      </div>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市科学技术协会</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65226421</span></h3>
                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <!--                    全年&ndash;&gt;-->
                    <div> <img style="height: 202px;width:202px " src="../../assets/images/zigongsi/669.png"></div>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">高层次人才享受医疗保健待遇
                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">1.预约诊疗：通过网上平台提出预约申请，按预约时间享受相应就医服务；</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">2.预约体检：通过网上平台提出预约申请，按预约时间享受体检服务；</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">3.保健咨询：通过专门配备的健康顾问或健康顾问团队联络人进行咨询。</div>
                      </div>

                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市高层次人才一站式服务中心</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-65224600</span></h3>

                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <span style="color: #ffffff">1</span>
                    <div style="width: 100%;height: 80px;background-color: #00056E">
                      <div style="color: #ffffff;font-size: 19px;font-weight: bold;letter-spacing: 1.5px;padding-top: 1.75rem">人才落户政策
                      </div>
                      <div style="float: right;margin-top: -4.5rem;margin-right: 3rem">  <img style="height: 61px;width:61px " src="../../assets/images/zigongsi/668.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <div  style="text-align: left">
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">符合相应条件的人才可申请落户。（具体内容请识别下方二维码）</div>

                      </div>
                      <div> <img style="height: 400px;width:400px " src="../../assets/images/zigongsi/610.png"></div>
                    </div>
                    <div style="float: left;width: 100%">
                      <div  style="text-align: left">

                      </div>

                      <h3 style="color: #00056E;font-size: 18px;margin-top: 30px;">经办部门：<span style="color: #1f1f1f">苏州市人才服务中心</span></h3>
                      <h3 style="color: #00056E;font-size: 18px;margin-top: 10px;">咨询电话：<span style="color: #1f1f1f">0512-12333、0512-65815515</span></h3>

                      <h4 style="color: #00BEFF;font-size: 13px;margin-top: 30px;">* 因人才政策动态优化调整，具体以申报通知为准</h4>
                    </div>
                    <p style="font-size: 16px;text-align: center">
                      各县区领军人才计划,申报信息也为大家奉上。
                    </p>
                    <div  style="text-align: left">
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">苏州张家港领军人才计划</div>
                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">申报时间：3月、8月</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州张家港工业和信息化局</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-56729083、0512-56729013</div>

                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州常熟“昆承英才”科技创新创业</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">领军人才计划</div>

                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">申报时间：3月、9月</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州常熟科学技术局</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-51929702</div>

                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州太仓科技领军人才计划</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">申报时间：4月-5月，8月-9月</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州太仓科学技术局</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-53890680、0512-53537560</div>

                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州昆山双创人才（团队）计划</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">申报时间：4月、8月</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州昆山科学技术局</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-57317683、0512-57393270</div>

                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州市吴江区创新创业领军人才计划</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">申报时间：4月、9月</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州市吴江区科学技术局</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-63981898</div>

                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州市吴中区东吴科技领军人才计划</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">申报时间：1月、4月、7月、10月</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州市吴中区科学技术局</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-67682696</div>

                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州市相城区科技领军人才计划</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">申报时间：4月和9月分两次申请</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州市相城区科学技术局</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-85182157</div>

                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州市姑苏区科技创新创业人才计划</div>
                      <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">申报时间：4月、8月</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州市姑苏区经济和科技局</div>
                      <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-68727615</div>

                        <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州工业园区科技领军人才创新创业工程</div>
                        <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">申报时间：3-5月，7-9月</div>
                        <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州工业园区科技创新委员会</div>
                          <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话：0512-66681651</div>

                          <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">苏州高新区科技创新创业领军人才计划</div>
                          <div style="padding-top: 22px;padding-left: 5rem;line-height: 1.5">申报时间：1-2月，7-8月</div>
                          <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">经办单位：苏州高新区科技创新局</div>
                            <div style="padding-top: 16px;padding-left: 5rem;line-height: 1.5">咨询电话： 0512-68751583</div>


                    </div>

                  </div>
                </div>


                <!-- 苏州腾康签约中国-沙特投资大会 -->
                <div v-if="id == '1001'">
                  <span style="font-size: 23px; font-weight: bold">
                    苏州腾康签约中国-沙特投资大会
                  </span>
                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2023-12-15 13:04:15</h5>
                    <!-- <h4>
                      业务展示
                    </h4> -->
                    <p style="font-size: 16px;text-align: center">
                    十年磨一剑，一朝飞腾远苏州腾康，剑指沙特，开拓海外
                  </p>
                    <p>
                      12月12日，中国-沙特阿拉伯投资峰会在北京顺利召开。会上，中国商务部副部长李飞与阿拉伯投资部长哈立德·法利赫对中沙合作进行了交流与讨论，并共同见证了60多个备忘录和协议的签署，内容涵盖石油化工、清洁能源、数字经济与人工智能、高端制造以及环保等多个领域。
                    </p>
                    <img style="height: 420px;width:600px " src="../../assets/images/zigongsi/641.png">
                    <!-- <h4>领跑行业</h4> -->
                    <p>
                      苏州腾康环保科技有限公司(TK)应邀出席大会，12月12日上午在北京与沙特投资部（MISA)以及沙特工程控股集团（EHG)签署了合作备忘录，未来将共建合资公司，将先进的环保技术和装备引入沙特利雅得。
                    </p>
                    <img style="height: 420px;width:600px " src="../../assets/images/zigongsi/642.png">
                    <p>中国在过去十年中一直是沙特最大的贸易伙伴，而沙特自2001年以来又一直是中国在中东地区最大的经贸合作对象。2022年，中沙双边贸易额超过1160亿美元，同比上涨超30%</p>
                    <p>12月12日的中国-沙特投资峰会进一步促进了双边合作，在能源、农业、旅游、矿业、金融以及环保等多个领域合作，一方面是，沙特投资部牵线搭桥，沙特企业和中国企业的强强联合，腾康（TK）得以借助环保方面的技术实力为沙特打造一个有机垃圾回收以及资源再生的全新环保体系，实现碳减排的目标，另一方面，腾康（TK）迈出国门，将沙特作为“一带一路”的起点，构筑合作典范，为将来更广阔的市场打下坚实的基础，落地更多“一带一路”沿线国家和地区。</p>
                    <img style="height: 420px;width:600px " src="../../assets/images/zigongsi/643.png">
                  </div>
                </div>
                <!-- 苏州腾康环保科技有限公司作为有机垃圾处理行业的领跑者，应邀参加第24届中国环博会。 -->
                <div v-if="id == '0'">
                  <span style="font-size: 23px; font-weight: bold">
                    苏州腾康环保科技有限公司作为有机垃圾处理行业的领跑者，应邀参加第24届中国环博会。
                  </span>
                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2023-04-19 08:02:12</h5>
                    <!-- <h4>
                      业务展示
                    </h4> -->
                    <p>
                      在本届环博会中腾康科技展示了引领有机垃圾处理领域中的有机垃圾处理技术与设备、智慧收运信息监管平台-eDIS与3D数字孪生系统-eTD及资源化综合利用产物与成功项目案例等。
                    </p>
                    <!-- <h4>领跑行业</h4> -->
                    <p>
                      腾康科技拥有着多年有机垃圾的处理经验、不断研发创新的精神和资源全循环利用的理念，继续领跑有机垃圾处理行业！
                    </p>
                    <p>欢迎各位客户前来我司参观和交流，我们期待与您共同探索有机垃圾处理领域的无限可能！</p>
                    <p>腾康科技，您身边的有机垃圾处理行业的领跑者。</p>
                  </div>
                </div>

                <!-- 苏州腾康环保科技有限公司受邀参加赣州市厨余垃圾资源化利用培训交流会 -->
                <div v-if="id == '1'">
                  <span style="font-size: 23px; font-weight: bold">
                    苏州腾康环保科技有限公司受邀参加赣州市厨余垃圾资源化利用培训交流会
                  </span>
                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2023-03-10 08:02:12</h5>
                    <p>
                      为加快推进赣州市生活垃圾分类工作，促进厨余垃圾处理无害化、减量化、资源化经研究，赣州市城市环境卫生协会，召开一次全市厨余垃圾资源化利用培训交流会。
                    </p>
                    <p>
                      苏州腾康科技作为有机废弃物资源化综合处置和利用的领跑者，结合多年的实践经验，在交流会中我司分享了先进技术、实践经验和厨余垃圾资源化技术的解决方案，并与政府和参会单位针对痛点问题进行了深入的探讨，深受好评。
                      “腾康科技”您身边的有机废弃物处置专业服务商。
                    </p>
                    <p>“腾康科技”您身边的有机废弃物处置专业服务商。</p>
                  </div>
                </div>

       <!-- 热烈祝贺我司荣获中国城市环境卫生协会科学技术奖科技进步一等奖 -->
       <div v-if="id == '2'">
                  <span style="font-size: 23px; font-weight: bold">
                    热烈祝贺我司荣获中国城市环境卫生协会科学技术奖科技进步一等奖
                  </span>
                  <!-- <h2></h2> -->

                  <div class="synopsis-text">
                <h5>苏州腾康环保科技有限公司   2023-01-17 11:02:12</h5>
                <p>
                    中国环境卫生协会发布2022年度中国城市环境卫生协会科学技术奖评选结果。共评出领军人才10人；我司和公司骨干分别荣获科技进步一等奖。
                </p>
                <img src="../../assets/images/teamwork/certificate-7.png" />
              </div>
                </div>

        <!-- 2022年开工动员大会 -->
           <div v-if="id == '3'">
                  <span style="font-size: 23px; font-weight: bold">
                    2022年开工动员大会
                  </span>
                  <div class="synopsis-text">
              <h5>苏州腾康环保科技有限公司   2022-02-07 17:16:44</h5>
            <p>
                2022年2月7日上午，我司召开2022开工动员大会，公司总经理及全体员工参与了会议。 </p>
          <p>
            在度过了一个欢乐、祥和、轻松的春节假期后，我们再次回到了工作岗位，相聚在这春意融融，喜庆正浓的时刻！俗话说“一年之计在于春”，那么在这新春伊始之时，我们就要以更加饱满的热情、更加扎实的作风、更加得力的措施，全身心地投入到工作中，为完成各项工作开好头、起好步！
          </p>
          <p>
            会议总我司各位领导都发表了对过去一年的总结和对未来一年的展望，使得大家对新的一年业务发展方向和奋斗目标有了更清晰认知，为后续各项工作有序的开展奠定了有利基础。
          </p>
          <p>
            会议最后，总经理为去年的优秀员工颁发了荣誉证书。相信有这些人作为榜样，公司的全体员工在新的一年中都能以更加坚定的信心，敢为人先的勇气，坚持不懈的毅力，促进各项目标任务的落实，共同推进2022年计划的完成，实现我司高质量的发展！
          </p>
            </div>
                </div>

     <!-- 喜报！我司共同参与研究的论文在著名Wiley期刊成功发表 -->
            <div v-if="id == '4'">
                  <span style="font-size: 23px; font-weight: bold">
                    喜报！我司共同参与研究的论文在著名Wiley期刊成功发表
                  </span>

                  <div class="synopsis-text">
              <h5>苏州腾康环保科技有限公司   2021-12-08 17:15:27</h5>
            <p>
                2021年12月7日，由武汉水生物研究所、中科院和苏州腾康环保科技公司共同参与关于《Effects of dietary housefly larvae (Musca domestica) on the growth performance, immunity and intestinal microbiota of Chinese soft-shelled turtle (Pelodiscus sinensis)》的研究论文，成功在著名Wiley期刊发表。            </p>

            </div>
                </div>

                    <!-- 苏州腾康环保科技有限公司完美亮相第22届中国环博会 -->
                    <div v-if="id == '5'">
                  <span style="font-size: 23px; font-weight: bold">
                    苏州腾康环保科技有限公司完美亮相第22届中国环博会
                  </span>

                  <div class="synopsis-text">
              <h5>苏州腾康环保科技有限公司   2021-04-26 17:16:05</h5>
            <p>
                在本次环博会期间，主要展示了由我司自主研发的处理能力为100t/d的预处理模块化设备。展会期间参观人员络绎不绝，行业内人员对我司研发的设备表示了高度的兴趣与认可。这次展会有力的促进了餐厨、厨余处理行业的转型升级，提高了我司产品的整体竞争力，为我司实现高质量发展提供了强大的动能。同时本次展会也让环保行业看到了一个充满活力与自信的苏州腾康环保科技有限公司正大步迈向充满光明的未来!
            </p>
            <div class="synopsis-img">
            <div style="text-align: center">
              <img
                src="../../assets/images/news/202204151650010965422.png"
                border="0"
                alt
                style="margin-bottom:20px;height: 650px;width: 553px;"
              />

              <img
                src="../../assets/images/solution/图片2.png"
                border="0"
                alt
                style="margin-bottom:20px"
              />
              <img
                src="../../assets/images/teamwork/staff-2.png"
                border="0"
                alt
                style="margin-bottom:20px"
              />
              <img
                src="../../assets/images/news/202204151650010804246.png"
                border="0"
                alt
              />
            </div>
          </div>
            </div>
                </div>

                <!-- 农村生活垃圾分类减量资源化模式及其影响因素与管理对策——以杭州市为例 -->
                <div v-if="id == '6'">
                  <span style="font-size: 23px; font-weight: bold">
                    农村生活垃圾分类减量资源化模式及其影响因素与管理对策——以杭州市为例
                  </span>

                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2022-03-24 11:28:12</h5>
                    <p>
                      据光明网消息，近日，成都高新法院就公开开庭审理了一起火锅店生产、销售有毒、有害食品案。该火锅店生产、销售的，就是我们俗称的“地沟油”。经鉴定，2018年4月至2020年11月22日，该店生产、销售添加“老油”的火锅锅底金额达1376028元。同时，依照《中华人民共和国食品安全法》第一百四十八条的规定，要求付某某支付十倍赔偿金共计13760280元。
                    </p>
                  </div>
                </div>

                <!-- 关于做好2020年畜禽粪污资源化利用工作的通知 -->
                <div v-if="id == '7'">
                  <span style="font-size: 23px; font-weight: bold">
                    关于做好2020年畜禽粪污资源化利用工作的通知
                  </span>

                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2020-07-23 15:54:13</h5>
                    <h3>农业农村部办公厅 财政部办公厅</h3>
                    <h3>关于做好2020年畜禽粪污资源化利用工作的通知</h3>
                    <p>
                      农办牧〔2020〕32号各省、自治区、直辖市及大连、青岛、厦门、宁波市农业农村（农牧、畜牧兽医）厅（局、委）、财政厅（局），新疆生产建设兵团农业农村局、财政局，黑龙江省农垦总局：
                      为贯彻党中央、国务院关于坚决打好污染防治攻坚战和改善农村人居环境的决策部署，落实《国务院办公厅关于加快推进畜禽养殖废弃物资源化利用的意见》（国办发〔2017〕48号），2020年中央财政继续支持畜禽粪污资源化利用工作。为确保政策落实到位，提高资金使用效益，现将有关事项通知如下。
                    </p>
                    <h4>一 、总体要求</h4>
                    <p>
                      各地要深入贯彻新发展理念，坚持政府支持、企业主体、市场化运作的方针，坚持源头减量、过程控制、末端利用的治理路径，以畜禽粪污肥料化和能源化利用为方向，聚焦生猪规模养殖场，全面推进畜禽粪污资源化利用。
                    </p>
                    <p>
                      2020年，继续支持畜牧大县整县推进畜禽粪污资源化利用（以下简称“整县推进项目”），同时，支持符合条件的非畜牧大县规模养殖场粪污治理（以下简称“规模养殖场粪污治理项目”）。整县推进项目要全面提升养殖场户粪污处理设施装备水平，兼顾畜禽粪肥田间贮存和利用设施建设，畅通粪肥还田利用渠道，畜禽粪污综合利用率达到90%以上，规模养殖场粪污处理设施装备配套率达到100%，构建起种养结合、农牧循环的可持续发展机制。规模养殖场粪污治理项目要突出重点区域和主要畜种，重点支持规模养殖场建设适应粪污肥料化利用要求的设施装备，进一步扩大处理规模，提升处理水平，确保今年年底前全省畜禽粪污综合利用率达到75%以上，规模养殖场粪污处理设施装备配套率达到95%以上
                    </p>
                    <h4>二、项目支持内容、条件和方式</h4>
                    <p>
                      整县推进项目今年安排剩余资金，按照既定实施方案支持规模养殖场和第三方处理主体开展建设。规模养殖场粪污治理项目按以下要求实施。
                    </p>
                    <p>
                      （一）支持内容。规模养殖场粪污治理项目重点支持规模养殖场饮水、清粪、环境控制、臭气处理、厌氧发酵或密闭式贮存发酵以及堆（沤）肥设施建设，购置粪肥运输和施用机械设备，配套建设粪污输送管网、田间贮存池等。
                    </p>
                    <p>
                      （二）支持条件。规模养殖场粪污治理项目支持范围为未实施2017—2020年中央财政和中央预算内投资畜禽粪污资源化利用整县推进项目的非畜牧大县，支持的养殖场应当具备以下条件：
                    </p>
                    <p>
                      1.养殖场用地、环评等相关手续齐全，已在农业农村部规模养殖场直联直报信息系统备案。
                    </p>
                    <p>
                      2.能够按要求建立粪污资源化利用台账，准确记录粪污处理和利用情况。
                    </p>
                    <p>
                      3.原则上按规定配套消纳用地或者签订粪污消纳协议，畜禽粪肥就地就近还田利用。
                    </p>
                    <p>
                      4.项目实施后规模养殖场臭气得到有效控制，液体粪污实现密闭贮存和处理。为避免多头、重复安排资金，已实施2019—2020年生猪规模化养殖场建设补助项目的养殖场不纳入支持范围。
                    </p>

                    <p>
                      （三）支持方式。各省要根据项目实施条件择优确定项目支持范围，按照高标准建设的要求合理确定补助标准，通过以奖代补、先建后补等方式对2020年规模养殖场粪污处理设施新建、改扩建给予一次性补助。
                    </p>
                    <h4>三、工作要求</h4>
                    <p>
                      各省农业农村（农牧、畜牧兽医）部门（以下简称“农业农村部门”）、财政部门要按照《农业农村部、财政部关于做好2020年农业生产发展等项目实施工作的通知》（农计财发〔2020〕3号）要求，切实加强协作配合，做好畜禽粪污资源化利用项目实施工作。
                    </p>
                    <p>
                      （一）制定实施方案。省级农业农村、财政部门要及时制定省级项目实施方案，明确实施条件、补助对象、补助标准、实施要求和监管措施等，于7月30日前报送农业农村部畜牧兽医局。整县推进项目县要切实加快项目实施进度，确保年底前完成项目目标任务。个别单体项目确因客观原因无法落地的，要抓紧调整。调整后的实施方案要及时报农业农村部、财政部备案。
                    </p>
                    <p>
                      （二）强化支持保障。要积极协调落实好沼气发电上网、生物天然气并入城市管网、用地用电等政策，为畜禽粪污资源化利用项目落地和运行提供支持保障。加快培育社会化服务组织，推动形成粪肥还田利用市场机制，促进种养结合发展。要根据产地农业种植特点，组建粪污处理和粪肥还田专家组，积极探索完善还田技术规范。
                    </p>
                    <p>
                      （三）严格资金使用管理。畜禽粪污资源化利用属于约束性任务，项目补助资金不得统筹使用。推动将畜禽粪污资源化利用与果菜茶有机肥替代化肥、耕地保护与质量提升、东北黑土地保护利用等项目统筹衔接实施，提高资金使用效果。
                    </p>
                    <p>
                      （四）加强绩效评估。省级财政、农业农村部门要按照《农业相关转移支付资金绩效管理办法》，对畜禽粪污资源化利用项目绩效目标实现情况、任务清单完成情况、资金使用情况等进行全面评估。各级农业农村部门要加强项目实施日常监管，及时解决项目实施中的问题，组织项目实施单位按要求填报项目进度，切实加强数据审核，确保数据真实、全面、有效。
                    </p>
                    <p>
                      （五）注重宣传总结。各级农业农村、财政部门要通过多种渠道加强政策宣传，为项目实施营造良好氛围。要因地制宜确定本地区主推模式，推动畜禽粪污低成本处理、就地就近还田利用。请各省于12月30日前将项目总结、整县推进典型模式报送农业农村部畜牧兽医局和财政部农业司。
                    </p>
                  </div>
                </div>
                <!-- 修订后的固体废物污染环境防治法 自2020年9月1日起施行 -->
                <div v-if="id == '8'">
                  <span style="font-size: 23px; font-weight: bold">
                    修订后的固体废物污染环境防治法 自2020年9月1日起施行
                  </span>

                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2020-05-12 13:22:20</h5>
                    <p>
                      改固废法的背景和重要意义是什么？此次主要作了哪些修改？修改后的固废法总结新冠肺炎疫情防控经验，作了哪些针对性规定？修改后的固废法对推进生活垃圾分类、过度包装和塑料污染治理、建筑垃圾污染环境防治作出哪些规定？全国人大常委会法工委有关部门负责人回答了记者提问。
                    </p>
                    <h3>问：修改固废法的背景和重要意义是什么？</h3>
                    <p>
                      答：党的十八大以来，以习近平同志为核心的党中央高度重视固体废物污染环境防治工作，习近平总书记多次就固体废物污染环境防治工作作出重要指示，亲自部署生活垃圾分类、禁止洋垃圾入境等工作。《中共中央国务院关于全面加强生态环境保护坚决打好污染防治攻坚战的意见》中明确提出，加快修改固体废物污染防治方面的法律法规。固体废物污染环境防治是打好污染防治攻坚战的重要内容，事关人民群众生命安全和身体健康，新冠肺炎疫情发生以来，以习近平同志为核心的党中央统筹推进疫情防控和经济社会发展工作，强调要坚定不移打好污染防治攻坚战，强化公共卫生法治保障。全国人大常委会高度重视固废法修改工作，2017年执法检查报告建议尽快启动固废法修订工作。本届常委会专门听取审议国务院关于研究处理固废法执法检查报告及审议意见情况的报告，在关于全面加强生态环境保护依法推动打好污染防治攻坚战的决议中明确提出加快固废法的修改工作。栗战书委员长强调，贯彻落实党中央关于生态文明建设的决策部署，推动打好污染防治攻坚战，是本届常委会的重大任务；要总结实践经验，抓紧研究修改固废法，健全污染防治长效机制。
                    </p>

                    <p>
                      此次全面修改固废法是贯彻落实习近平生态文明思想和党中央关于生态文明建设决策部署的重大任务，是依法推动打好污染防治攻坚战的迫切需要，是健全最严格最严密生态环境保护法律制度和强化公共卫生法治保障的重要举措。
                    </p>
                    <h3>问：此次对固废法主要作了哪些修改？</h3>
                    <p>
                      答：此次修改固废法，坚持以人民为中心的发展思想，贯彻新发展理念，突出问题导向，总结实践经验，回应人民群众期待和实践需求，健全固体废物污染环境防治长效机制，用最严格制度最严密法治保护生态环境。主要作了以下修改：
                    </p>
                    <p>
                      一是，明确固体废物污染环境防治坚持减量化、资源化和无害化原则。
                    </p>
                    <p>
                      二是，强化政府及其有关部门监督管理责任。明确目标责任制、信用记录、联防联控、全过程监控和信息化追溯等制度，明确国家逐步实现固体废物零进口。
                    </p>
                    <p>
                      三是，完善工业固体废物污染环境防治制度。强化产生者责任，增加排污许可、管理台账、资源综合利用评价等制度。
                    </p>
                    <p>
                      四是，完善生活垃圾污染环境防治制度。明确国家推行生活垃圾分类制度，确立生活垃圾分类的原则。统筹城乡，加强农村生活垃圾污染环境防治。规定地方可以结合实际制定生活垃圾具体管理办法。
                    </p>
                    <p>
                      五是，完善建筑垃圾、农业固体废物等污染环境防治制度。建立建筑垃圾分类处理、全过程管理制度。健全秸秆、废弃农用薄膜、畜禽粪污等农业固体废物污染环境防治制度。明确国家建立电器电子、铅蓄电池、车用动力电池等产品的生产者责任延伸制度。加强过度包装、塑料污染治理力度。明确污泥处理、实验室固体废物管理等基本要求。
                    </p>
                    <p>
                      六是，完善危险废物污染环境防治制度。规定危险废物分级分类管理、信息化监管体系、区域性集中处置设施场所建设等内容。加强危险废物跨省转移管理，通过信息化手段管理、共享转移数据和信息，规定电子转移联单，明确危险废物转移管理应当全程管控、提高效率。
                    </p>
                    <p>
                      七是，健全保障机制。增加保障措施一章，从用地、设施场所建设、经济技术政策和措施、从业人员培训和指导、产业专业化和规模化发展、污染防治技术进步、政府资金安排、环境污染责任保险、社会力量参与、税收优惠等方面全方位保障固体废物污染环境防治工作。
                    </p>
                    <p>
                      八是，严格法律责任。对违法行为实行严惩重罚，提高罚款额度，增加处罚种类，强化处罚到人，同时补充规定一些违法行为的法律责任。比如有未经批准擅自转移危险废物等违法行为的，对法定代表人、主要负责人、直接负责的主管人员和其他责任人员依法给予罚款、行政拘留处罚。
                    </p>
                    <h3>
                      问：固废法总结新冠肺炎疫情防控经验，作了哪些针对性规定？
                    </h3>
                    <p>
                      答：新冠肺炎疫情防控，对固体废物管理提出了新要求，根据有关方面的意见，增加以下规定：
                    </p>
                    <p>
                      第一，切实加强医疗废物特别是应对重大传染病疫情过程中医疗废物的管理。
                    </p>
                    <p>
                      一是，明确医疗废物按照国家危险废物名录管理。县级以上地方人民政府应当加强医疗废物集中处置能力建设。
                    </p>
                    <p>
                      二是，明确监管职责。县级以上人民政府卫生健康、生态环境等主管部门应当在各自职责范围内加强对医疗废物收集、贮存、运输、处置的监督管理，防止危害公众健康、污染环境。
                    </p>
                    <p>
                      三是，突出主体责任。医疗卫生机构应当依法分类收集本单位产生的医疗废物，交由医疗废物集中处置单位处置。医疗废物集中处置单位应当及时收集、运输和处置医疗废物。医疗卫生机构和医疗废物集中处置单位应当采取有效措施，防止医疗废物流失、泄漏、渗漏、扩散。
                    </p>
                    <p>
                      四是，完善应急保障机制。重大传染病疫情等突发事件发生时，县级以上人民政府应当统筹协调医疗废物等危险废物收集、贮存、运输、处置等工作，保障所需的车辆、场地、处置设施和防护物资。有关主管部门应当协同配合，依法履行应急处置职责。
                    </p>
                    <p>
                      五是，要求各级人民政府按照事权划分的原则安排必要的资金用于重大传染病疫情等突发事件产生的医疗废物等危险废物应急处置。
                    </p>
                    <p>
                      第二，明确有关实验室固体废物管理的基本要求。规定各级各类实验室及其设立单位应当加强对实验室产生的固体废物的管理，依法收集、贮存、运输、利用、处置实验室固体废物。实验室固体废物属于危险废物的，应当按照危险废物管理。
                    </p>
                    <p>
                      第三，加强农贸市场等环境卫生治理。规定农贸市场、农产品批发市场等应当加强环境卫生管理，保持环境卫生清洁，对所产生的垃圾及时清扫、分类收集、妥善处理。
                    </p>
                    <h3>问：固废法对推进生活垃圾分类作了哪些针对性规定？</h3>
                    <p>
                      答：习近平总书记高度重视生活垃圾分类工作，亲自安排部署，多次作出重要指示。党的十九届四中全会决定中提出，普遍实行垃圾分类制度。固废法针对生活垃圾分类主要作了以下规定：
                    </p>
                    <p>
                      一是明确国家推行生活垃圾分类制度，生活垃圾分类坚持政府推动、全民参与、城乡统筹、因地制宜、简便易行的原则。
                    </p>
                    <p>
                      二是要求县级以上地方政府加快建立分类投放、分类收集、分类运输、分类处理的生活垃圾管理系统，实现生活垃圾分类制度有效覆盖，并建立生活垃圾分类工作协调机制，加强和统筹生活垃圾分类管理能力建设。
                    </p>
                    <p>
                      三是强调各级政府及其有关部门应当组织开展生活垃圾分类宣传，教育引导公众养成生活垃圾分类习惯，督促和指导生活垃圾分类工作。
                    </p>
                    <p>
                      四是规定产生生活垃圾的单位、家庭和个人应当依法履行分类投放义务。任何单位和个人都应当依法在指定的地点分类投放生活垃圾，不得随意倾倒、抛撒、堆放或者焚烧。地方政府建立生活垃圾处理收费制度，要遵循产生者付费、差别化管理原则。
                    </p>
                    <p>
                      五是强调县级以上地方政府应当统筹生活垃圾公共转运、处理设施与收集设施有效衔接，加强分类收运体系和再生资源回收体系在规划、建设、运营等方面的融合。六是规定有害垃圾、厨余垃圾处理和生活垃圾分类经费保障、违法行为处罚等内容。
                    </p>
                    <h3>
                      问：固废法对过度包装、塑料污染治理作了哪些针对性规定？
                    </h3>
                    <p>
                      答：2019年9月，习近平总书记在河南考察调研时强调，要注意节约环保，杜绝过度包装，避免浪费和污染环境。习近平总书记2019年9月主持召开中央深改委第十次会议审议通过的《关于进一步加强塑料污染治理的意见》对有力有序有效治理塑料污染作了部署。固废法针对过度包装、塑料污染治理主要作了以下规定：
                    </p>
                    <p>第一，关于过度包装治理。</p>
                    <p>
                      一是明确有关部门要加强产品生产和流通过程管理，避免过度包装。
                    </p>
                    <p>
                      二是明确包装物的设计、制造应当遵守国家有关清洁生产的规定，要求组织制定有关标准防止过度包装造成环境污染。
                    </p>
                    <p>
                      三是强调生产经营者应当遵守限制商品过度包装的强制性标准，避免过度包装。市场监督管理部门和有关部门应当加强对过度包装的监督管理。
                    </p>
                    <p>
                      四是要求生产、销售、进口依法被列入强制回收目录的包装物的企业，应当按照规定对包装物进行回收。
                    </p>
                    <p>
                      五是规定电子商务、快递、外卖等行业应当优先采用可重复使用、易回收利用的包装物，优化物品包装，减少包装物的使用，并积极回收利用包装物。商务、邮政等主管部门应当加强监督管理。
                    </p>
                    <p>六是明确国家鼓励和引导消费者使用绿色包装和减量包装。</p>

                    <p>第二，关于塑料污染治理。</p>
                    <p>
                      一是明确国家依法禁止、限制生产、销售和使用不可降解塑料袋等一次性塑料制品。
                    </p>
                    <p>
                      二是要求商品零售场所开办单位、电子商务平台企业和快递企业、外卖企业按照规定向商务、邮政等主管部门报告塑料袋等一次性塑料制品的使用、回收情况。
                    </p>
                    <p>
                      三是规定国家鼓励和引导减少使用塑料袋等一次性塑料制品，推广应用可循环、易回收、可降解的替代产品。
                    </p>
                    <p>
                      此外，固废法还对旅游、住宿等行业按照规定推行不主动提供一次性用品和未遵守限制商品过度包装的强制性标准、禁止使用一次性塑料制品规定的处罚等作了规定。
                    </p>
                    <h3>
                      问：固废法对建筑垃圾污染环境防治作了哪些针对性规定？
                    </h3>
                    <p>
                      答：建筑垃圾产生量大、消纳任务重，固废法加大推进建筑垃圾污染环境防治工作的力度，增加以下规定：
                    </p>
                    <p>
                      一是要求政府加强建筑垃圾污染环境的防治，建立分类处理制度，制定包括源头减量、分类处理、消纳设施和场所布局及建设等在内的建筑垃圾污染环境防治工作规划。
                    </p>
                    <p>
                      二是明确国家鼓励采用先进技术、工艺、设备和管理措施，推进建筑垃圾源头减量，建立建筑垃圾回收利用体系。要求政府推动建筑垃圾综合利用产品应用。
                    </p>
                    <p>
                      三是规定环境卫生主管部门负责建筑垃圾污染环境防治工作，建立建筑垃圾全过程管理制度，规范相关行为，推进综合利用，加强建筑垃圾处置设施、场所建设，保障处置安全，防止污染环境。
                    </p>
                    <p>
                      四是要求工程施工单位编制建筑垃圾处理方案并报备案。明确工程施工单位不得擅自倾倒、抛撒或者堆放工程施工过程中产生的建筑垃圾。
                    </p>
                    <p>
                      五是规定建筑垃圾转运、集中处置等设施建设用地保障和擅自倾倒、抛撒建筑垃圾的处罚等内容。
                    </p>
                  </div>
                </div>

                <!-- 北京、深圳5月1日起开始垃圾分类 谁是下一个风口？ -->
                <div v-if="id == '9'">
                  <span style="font-size: 23px; font-weight: bold">
                    北京、深圳5月1日起开始垃圾分类 谁是下一个风口？
                  </span>

                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2020-05-12 14:46:59</h5>
                    <p>
                      “今年为垃圾分类试点城市考核年和固废法大修案落地年，预计各城市将逐渐深入参与。”申港证券近期发布的研报称：“垃圾分类可重塑固废产业链脉络，将带来固废市场巨大机会，看好静脉产业园投资运营、厨余EPC业务和环卫服务。”
                    </p>
                    <p>
                      项目预算金额5230.36万元。大名县餐厨垃圾处理PPP项目采用建设-运营-移交模式，项目合作期限为30年，其中建设期为2年，运营期为28年。
                    </p>
                    <h3>北京、深圳开启“垃圾分类</h3>
                    <p>
                      5月1日起实施的《深圳市生活垃圾分类管理条例》，根据深圳实际情况，创设了家庭厨余垃圾拆袋投放制度、年花年桔分类回收制度、住宅区楼层撤桶制度，创新了生活垃圾定时定点投放制度，并设立了垃圾减量日。
                    </p>
                    <p>
                      《深圳市生活垃圾分类管理条例》将生活垃圾分为可回收物、厨余垃圾、有害垃圾、其他垃圾四大类，同时授权主管部门适时调整生活垃圾分类标准。要求市政府按照谁产生、谁付费和差别化收费的原则，完善生活垃圾处理收费制度，逐步实行分类计价、计量收费。单位或个人未按要求投放生活垃圾且拒不改正的，对个人处50元以上200元以下罚款，对单位处5000元以上5万元以下罚款。
                    </p>
                    <p>
                      新修订的《北京市生活垃圾管理条例》也将于5月1日正式施行。
                    </p>
                    <p>
                      据悉，5月1日至7月31日，北京市将集中开展为期3个月的生活垃圾分类强化执法专项行动，重点查处生活垃圾混堆混放、收运企业混装混运等行为。
                    </p>
                    <p>新版《北京市生活垃圾管理条例》四分类法</p>
                    <p>
                      《条例》规定，个人未按规定分类投放生活垃圾，由生活垃圾分类管理责任人进行劝阻；对拒不听从劝阻的，生活垃圾分类管理责任人应当向城市管理综合执法部门报告，由城市管理综合执法部门给予书面警告；再次违反规定的，处50元以上200元以下罚款。对于应当受到处罚的个人，自愿参加生活垃圾分类等社区服务活动的，不予行政处罚。
                    </p>
                    <p>
                      此外，北京市文化和旅游局行业管理处副处长乔剑平表示，为推动等级景区开展垃圾分类，各景区将对垃圾桶及标识牌进行完善。同时，对垃圾分类、存放、处置不达标景区，在年度复核中要求景区限期整改，整改仍不合格者要降低或取消景区等级资质。
                    </p>
                    <p>
                      据不完全统计，今年以来已经出台垃圾分类相关政策的城市还包括：西安、南昌、广州、铜陵、咸阳、日照、绍兴、惠州、莆田、东营、聊城等。
                    </p>
                    <h3>或将重塑固废产业链</h3>
                    <p>
                      垃圾分类让餐厨垃圾处理行业的前景豁然开朗。”上海洁鹿环保总经理彭丽军告诉上证报：“餐厨垃圾处理第一步‘收集’，是成本集聚的环节，也是效率低下的症结。垃圾分类有望解决这一痛点。
                    </p>
                    <p>
                      垃圾分类可重塑固废产业链脉络，我们最看好其中的静脉产业园投资运营、厨余EPC业务和环卫服务。”申港证券研报指出。
                    </p>
                    <p>
                      多家机构在相关研报中看好垃圾分类相关概念股，包括垃圾焚烧龙头伟明环保、大固废布局稳步推进的瀚蓝环境、餐厨垃圾处理龙头维尔利、环卫企业龙马环卫、玉禾田、侨银环保等。
                    </p>
                    <p>
                      以瀚蓝环境为例，公司拥有9个固废产业园、23个垃圾焚烧发电项目、3个垃圾填埋场、3个垃圾压缩转运、3个危废处理等项目，生活垃圾焚烧发电处理总规模可达33300吨/日，已形成完整的固废一体化产业链。
                    </p>
                    <p>
                      据悉，在农业垃圾处理方面，除了已试运营的乐昌市农业资源循环利用处理中心示范项目外，2020年瀚蓝环境还有3个农业垃圾处理项目将陆续投入使用，公司农业垃圾处理量将增至125吨/日。
                    </p>
                    <p>
                      再看维尔利，2019年年报显示，2019年公司新增合同37.14亿元，同比增加42.08%。其中，新中标渗滤液订单
                      18.52亿元，同比增加49.6%；餐厨厨余订单7.67 亿元，同比增加
                      141.64%。
                    </p>
                    <p>
                      据悉，2019年公司陆续承接了上海金山湿垃圾处置项目、上海嘉定湿垃圾处置项目，进一步夯实公司在湿垃圾处理行业的市场地位。
                    </p>
                    <p>
                      对2020年的经营，维尔利表示，在水处理业务板块，公司将抓住生活垃圾分类有关政策落地的机遇，加大力度开发沼液处置业务市场。
                    </p>
                  </div>
                </div>

                <!-- RCR新文：植物油和动物脂肪生产可再生柴油对全球暖化潜势的影响 -->
                <div v-if="id == '10'">
                  <span style="font-size: 23px; font-weight: bold">
                    RCR新文：植物油和动物脂肪生产可再生柴油对全球暖化潜势的影响
                  </span>
                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2020-07-10 09:06:16</h5>
                    <p>
                      能源是人类社会进步最为重要的基础，化石能源一直是人类社会发展的主要动力。随着工业的发展、人口增长以及人类对能源的巨大需求，在化石能源大规模开采利用过程中造成了严重的环境污染与不可逆的环境破坏，威胁着经济和社会可持续发展，开发替代的可再生能源是非常必要和迫切的。
                    </p>
                    <p>
                      人们研究植物油燃料最初的兴趣来自于燃料供给的不足。近年来，由于原油价格的提高、矿物燃料资源的限制及环保等因素影响，人们的着眼点又集中于从植物油和动物脂肪中制取生物柴油，使用可再生的燃料来减轻有害污染物排放和碳排放。本文评价了以废弃家禽脂肪、牛油和大豆油为原料制备可再生生物柴油的生命周期过程对全球暖化潜势的影响。
                    </p>
                    <p>
                      本文以废弃家禽脂肪、牛油和大豆油作为游离脂肪酸的来源，与甘油三酯反应制备可再生生物柴油。以甘油三月桂酸酯、月桂酸、蛋白质和水作为动物脂肪的模型化合物进行皂化和脱酸实验，并结合化学过程模拟、热力学方程及运动学方程进行能源和物料平衡分析，其中从头计算法用于估算缺失数据，以便建立生命周期的库存模型。利用大豆油（考虑土地利用变化效应）、家禽脂肪、牛油制备可再生生物柴油的生命周期温室气体排放量降至-8.7~47.2g
                      CO2当量/MJ，排放量变化取决于副产物和所使用的分配规则。
                    </p>
                    <div style="text-align: center">
                      <img
                        src="../../assets/images/news/202007101594343444216.png"
                        border="0"
                        alt
                        style="height: 700px; width: 800px; margin-bottom: 10px"
                      />
                      <p style="text-align: center">
                        图1.
                        牛油、家禽废弃脂肪和大豆油转化为可再生柴油的生命周期过程流程图
                      </p>
                      <p style="margin-bottom: 30px; text-align: center">
                        图2. (a) 大豆农场、家禽养殖场、牛养殖场的数目; (b)
                        美国电网和炼油厂分布图
                      </p>
                      <img
                        src="../../assets/images/news/202007101594343538359.png"
                        border="0"
                        alt
                        style="height: 700px; width: 800px; margin-bottom: 10px"
                      />
                      <p style="margin-bottom: 30px; text-align: center">
                        图3. 可再生生物柴油的全球暖化潜势(a)废弃家禽脂肪; (b)
                        牛油. 基于不同原料成分的上界和下界
                      </p>
                      <img
                        src="../../assets/images/news/202007101594343489727.png"
                        border="0"
                        alt
                        style="height: 700px; width: 800px; margin-bottom: 10px"
                      />
                      <p style="margin-bottom: 30px; text-align: center">
                        图4.
                        基于不同间接土地利用变化模型大豆油生产生物柴油的全球暖化潜势
                      </p>
                      <img
                        src="../../assets/images/news/202007101594343709856.png"
                        border="0"
                        alt
                        style="height: 700px; width: 800px; margin-bottom: 10px"
                      />
                      <p style="margin-bottom: 30px; text-align: center">
                        图 5.
                        采用质量分配(MA)或系统扩展(SE)方法对牛油、禽脂和大豆油生产的可再生柴油的全球暖化潜势进行研究
                      </p>
                      <img
                        src="../../assets/images/news/202007101594343349872.png"
                        border="0"
                        alt
                        style="height: 700px; width: 800px; margin-bottom: 10px"
                      />

                      <p style="margin-bottom: 30px; text-align: center">
                        图 6. 与文献中生物柴油和可再生柴油的全球暖化潜势进行比较
                      </p>
                    </div>
                  </div>
                </div>

                <!-- 国家“十四五”生态环境政策改革重点与创新路径研究 -->
                <div v-if="id == '11'">
                  <span style="font-size: 23px; font-weight: bold">
                    国家“十四五”生态环境政策改革重点与创新路径研究
                  </span>

                  <div class="synopsis-text">
                    <h5>苏州腾康环保科技有限公司   2020-07-23 09:33:40</h5>
                    <p>1. 生态环境政策改革基础与需求分析</p>
                    <p>
                      党的十八大以来，我国生态环境政策取得显著进展，尤其是“十三五”时期，生态环境政策加速改革，基本建立了政策体系，为深入推进生态文明建设、建设美丽中国建设提供了重要动力机制。
                    </p>
                    <p>1.1 “十三五”生态环境政策进展</p>
                    <p>
                      “十三五”时期，初步形成了党政领导、质量管理、监管落责、市场参与、多元治理的生态环境政策体系，为顺利完成生态环境规划目标和打赢打好污染防治攻坚战提供了充分支撑与保障。
                    </p>
                    <p>
                      生态环境责任体系改革成效显著。新组建的生态环境部强化了政策规划标准制定、监测评估、监督执法、督察问责的“四统一”职责。中央生态环境保护督察、党政领导干部生态环境损害责任追究等政策相继出台实施，“党政同责、一岗双责”得以有效落实，形成了权责明确、各司其职、齐抓共管的“大环保”工作格局。省级以下生态环境机构监测监察执法垂直管理制度改革、生态环境保护综合行政执法改革稳步推进。
                    </p>
                    <p>
                      环境质量精细化管理政策体系初步形成。“水”“气”“土”污染防治相关法律及排放限值等相继修订，切实反映了环境质量改善现实要求。环境质量考核目标财政奖励政策初步建立。推进建立面向民生满意导向的生态环境质量评价机制，将公众环境满意度作为相关示范区建设、黑臭水体治理等重要评价指标。
                    </p>
                    <p>
                      “四大结构调整”政策推动绿色发展成效明显。坚决取缔“十小”，严控钢铁、水泥产能，各地“散乱污”企业清理整顿基本完成，截至2018年年底全国钢铁、煤炭分别化解落后过剩产能1.55亿吨、8.1亿吨。环保电价、火电超低排放电价补贴、北方地区清洁取暖补贴稳步推进，截至2018年全国实现超低排放的煤电机组约8.1亿千瓦。京津冀地区煤炭运输和铁矿石集疏港实现“公转铁”。
                    </p>
                    <p>
                      生态环境监管体系不断完善。积极推进排污许可改革，已发布63项相关规范标准，截至2018年年底累计完成18个行业3.9万多家企业排污许可证核发。建立“网格化”“双随机、一公开”等日常监管机制，2018年全国环境行政罚款共152.8亿元，是新环境保护法实施前2014年的4.8倍。随着流域控制单元、大气网格化、“三线一单”等工作的推进，生态环境空间管控政策从探索到实践都取得了积极进展。
                    </p>
                    <p>
                      生态环境市场经济机制基本建立。强化生态环境保护资金保障，2018年生态环境部参与管理的中央环保专项资金达到551亿元；各类绿色债券2016—2018年的总规模达6077.49亿元。环境保护税全面开征，“绿色信用”“绿色保险”日益发挥重要作用。排污交易权使用试点由点到面稳步推进，碳排放权交易试点继续深入。
                    </p>
                    <p>
                      生态环境多元治理格局初步形成。“机动式”“点穴式”“会战式”强化监督帮扶作为行政监管的一种探索形式，与中央环保督察构成了具有我国特色的环保督察体系，带动了全社会环保责任的落实。强化信息公开，推动企业主体责任落实，引导社会各方开展环保监督。发布《公民生态环境行为规范（试行）》，公众参与环保的意识与行动均有所提升。
                    </p>
                    <p>1.2 “十四五”改革面临的挑战</p>
                    <p>
                      我国生态环境质量持续好转，呈现稳中向好趋势，但成效并不稳固，生态文明建设“三期叠加”期需要政策改革提供长效机制，生态环境治理能力与治理体系现代化更需要政策改革来提供抓手，生态环境政策体系和长效机制仍存在短板与挑战，亟待在“十四五”时期通过改革与创新予以解决。
                    </p>
                    <p>
                      落实生态环境体制机制改革的配套政策有待完善。环保垂直改革与综合执法改革的配套政策与机制尚未明确建立。部门间、不同层级间生态环保职责有待进一步明晰，流域监督管理局、区域环境督查局、派出机构与地方环境执法之间的分工、协调联动机制有待进一步完善。
                    </p>
                    <p>
                      环境质量管理政策体系仍不完善。污染排放管理政策与环境质量管理政策间衔接不足，固定源排污许可制度许可浓度与许可排放总量主要参考排放标准、总量指标，没有与环境质量目标及改善路径紧密衔接。
                    </p>
                    <p>
                      精细化生态环境监管执法尚未实现常态化。生态环境行政主导化倾向依然存在，自由裁量权规范不够，个别地方仍存在“一刀切”问题。人体健康、生态健康、环境风险等领域标准制定与监管仍处于起步阶段。
                    </p>
                    <p>
                      市场导向的生态环境经济政策效用尚未充分发挥。全社会环保投入力度仍然不足，与OECD国家相比差距明显；尚未建立起与地区发展权相匹配的生态补偿机制；绿色税制不健全，环境保护税和消费税调控、征收范围较窄，资源税收费标准过低，难以调控消费行为。
                    </p>

                    <p>
                      企业和社会生态环境治理作用有待进一步激发。企业环境信息披露、信用评价、联合惩戒机制不健全，违法成本低导致一些地方企业宁愿罚款也不愿参与环境治理；社会组织和公众参与的诉求表达、心理干预、矛盾调处、权益保障机制等有待探索实施。
                    </p>
                    <p>“十四五”改革需求</p>
                    <p>
                      十四五”期间的生态环境保护改革与创新要在习近平生态文明思想的指引下，形成持久战与歼灭战相结合的生态环境保护态势，为建设美丽中国开好局、起好步，这就需要充分发挥好生态环境政策改革的关键作用。
                    </p>
                    <p>
                      一是从推进体制改革落地、完善环境质量管理政策体系，强化环境风险防范和环境健康风险管理,建立更加科学合理的生态环境质量管理政策体系。
                    </p>
                    <p>
                      二是强化政策的统筹实施、综合调控、协同治理机制，解决部门分割等原因造成的政策衔接不足、政策协同不强、政策优化决策不够等问题。
                    </p>
                    <p>
                      三是更加突出政策调控差异化、管理精细化、决策科学化，实行差异化分级分类管理。
                    </p>
                    <p>
                      四是需要充分发挥市场体系优化配置生态环境资源的基础作用，切实把市场主体的环境行为交由市场来调节，打通“两山”转化通道，构建调控经济体系全链条的生态环境经济政策体系。
                    </p>
                    <p>
                      五是进一步推进政策手段的法制化建设，确保生态阈值和环境阈值不被突破，实行最严格的制度、最严密的法治，保障公民环境权，形成全社会动员格局。
                    </p>
                    <p>2. 生态环境政策改革思路与框架</p>
                    <p>
                      “十四五”时期环境政策改革与创新要全面贯彻落实习近平生态文明思想，通过深化生态环境政策改革来应对生态环境保护的“三期叠加”阶段面临的机遇和挑战，促进产业经济高质量发展和生态环境高水平保护，坚持“质量核心、体系优化”“正本溯源、标本兼治”“系统施策，综合治理”“空间管控、精细调控”“考评落责、多元共治”“两个大局、内外统筹”六项改革原则，通过强化系统管理、空间管控、结构调整、综合调控、协同治理、内外统筹，突出政策调控差异化、管理精细化、决策科学化。综合考虑“十四五”生态环境政策改革形势，基于问题导向和需求导向进行系统设计，政策体系框架包括结构调整、空间管控、质量管理、市场经济、多元治理、全球合作等六大政策领域以及夯实严密法治、监管执法、政策执行和科技创新四项保障支撑。改革与创新的目标是推进实现生态环境治理体系和能力现代化，形成美丽中国建设的长效政策机制。
                    </p>
                    <img
                      src="../../assets/images/news/202007231595468016890.png"
                    />
                    <p>图1“十四五”生态环境政策改革框架</p>
                    <p>3. “十四五”生态环境政策改革重点</p>

                    <p>3.1 深化绿色发展四大结构调整政策</p>
                    <p>
                      “十四五”时期，结构调整结构是生态环境保护工作的重心，政策重心进一步从污染治理末端的污染防治向污染治理前端的发展源头调整，更加突出绿色发展和生态环境保护工作的深度融合，结构调整成为生态环境政策体系调控的主要领域，尤其是创新发挥好激励引导类政策工具的作用。
                    </p>
                    <p>
                      一是以非电重点行业超低排放补贴、水电价阶梯激励政策为主要“抓手”促进产业结构绿色转型调整。深化供给侧结构性改革政策创新，持续推进“散乱污”治理政策，深化工业行业超低排放政策，建立排放绩效导向、阶梯式激励、差别化补贴的超低排放补贴模式；完善节能环保产业“提质上档”驱动政策，加强税式优惠等政策激励；完善绿色产品生产与供给政策，继续推进完善绿色采购清单。
                    </p>
                    <p>
                      二是以补贴、电价改革为主要抓手推进能源节约利用与结构调整。完善清洁能源推广和提效政策，继续实施清洁取暖补贴政策，完善以财政投入为引导、金融资金和社会资本共同参与的投融资模式，扩大大气污染防治重点区域煤炭减量替代政策范围；继续完善资源定价政策改革，协同建立健全用能权、用水权、排污权、碳排放权初始分配制度。
                    </p>
                    <p>
                      三是以补贴、税收优惠激励为抓手推进交通运输结构优化调整。通过多元投入、税收优惠等政策推进交通运输结构调整，实施“车—油—路”一体的轻型车超低排放，推动实施新车碳排放标准；完善柴油货车税收优惠政策，扩大汽车消费税征收范围，进一步拉开汽油和柴油的消费税税率差距，加强细化车船税税额；制定实施岸电激励政策。
                    </p>
                    <p>
                      四是以补贴、电价等激励政策为重点推动农业绿色发展。调整现有对农药、化肥和地膜的补贴政策，研究低毒低残留农药、有机肥、可回收地膜的补贴政策；探索多元化、差别化的农业补贴激励机制，建立健全耕地、草原、渔业水域等领域的生态补偿政策体系；实施电价优惠推进绿色农业生产和农村污水处理设施运营，研究制定有机肥厂、规模化大型沼气工程、畜禽粪污第三方处理用地用电优惠政策。
                    </p>
                    <p>3.2 健全生态环境空间管控政策</p>
                    <p>
                      “十四五”时期，空间资源的优化配置和守牢底线是一项新的挑战，要实施好空间的生态环境属性差异化管控，需要在“十三五”生态环境空间管控工作的基础上，进一步健全生态环境空间管控政策体系。
                    </p>

                    <p>
                      一是实施水气土环境要素质量分区管控政策。继续推进优化实施以控制单元为基础的水环境质量目标管理，将流域生态环境保护责任层层分解到各级行政区域，全面建立完善流域水环境质量责任管理体系；重点区域实行大气环境分区管控，强化行政处罚、环保督察、经济政策等组合调控；完善建设用地准入清单政策和农用地分类管理政策，重点制定受污染耕地安全利用和综合防控政策，制定重点区域、重点行业、典型地块污染风险防控政策。
                    </p>
                    <p>
                      二是建立重要生态空间分区管控政策。以生态保护红线和自然保护地为重点，合理分区实行差别化管控要求，建立健全生态补偿长效机制和多渠道生态建设资金投入机制；加强海洋保护区、海洋生态保护红线区、海洋生态修复工程实施区“三类区域”分类管控，积极制定海洋生态补偿、损害赔偿、海洋垃圾清理等政策，建立激励与约束并重的政策体系。
                    </p>
                    <p>
                      三是推进“三线一单”为基础的生态环境空间管控政策。将污染源普查、环境调查、环境监测、排放清单、排污许可、执法审批数据与“三线一单”和功能区划成果融合，形成“三线一单”数据标准、技术规范、配套规整和管理政策。
                    </p>
                    <p>
                      四是加快完善生态环境空间管控配套政策。综合生态环境各要素管控分区及重点资源利用管控分区，建立区域生态环境空间评价制度和生态环境管控清单准入制；建立生态环境空间监测考评机制，基于分区构建“纵横联合”的生态环境监管管理体系，建立不同类型生态环境空间监督与绩效考核评价体系。
                    </p>
                    <p>3.3 完善环境质量管理政策机制</p>
                    <p>
                      环境质量持续改善依然是“十四五”时期的核心工作，进一步健全环境质量管理政策机制，不仅进一步强化考核落责，更要加强正向激励，形成政策链条和体系，在环境质量达标管理中充分发挥多种政策工具的协同作用。
                    </p>
                    <p>
                      一是完善以考评为主的生态环境质量管理政策体系。推进建立完善的生态环境质量监测、评价、考核、责任、奖惩环境质量管理体系，并强化考核结果与财政资金、官员升迁等政策的衔接增效。
                    </p>
                    <p>
                      二是完善环境质量管理的污染物总量减排制度。实施挥发性有机物、总磷、总氮约束性指标管理，实施固定源与非固定源“双控”管理。
                    </p>

                    <p>
                      三是建立全生命周期生态环境风险防范和应急管理政策体系。完善事前防范和管理标准体系建设，完善事中处置政策，完善环境事故事后赔偿和修复政策，进一步健全环境损害鉴定评估与赔偿技术规范体系和污染治理与修复制度体系。
                    </p>
                    <p>
                      四是健全生态环境健康风险管控环境政策体系。推进环境健康风险管理试点，建立环境体检、责任保险、专业服务、风险防范、损害理赔为一体的绿色金融环境风险防范体系，加强生态环境健康风险信息透明度机制和能力建设。
                    </p>
                    <p>
                      五是推进“三线一单”为基础的生态环境空间管控政策。将污染源普查、环境调查、环境监测、排放清单、排污许可、执法审批数据与“三线一单”和功能区划成果融合，形成“三线一单”数据标准、技术规范、配套规整和管理政策。
                    </p>
                    <p>
                      六是加快完善生态环境空间管控配套政策。综合生态环境各要素管控分区及重点资源利用管控分区，建立区域生态环境空间评价制度和生态环境管控清单准入制；建立生态环境空间监测考评机制，基于分区构建“纵横联合”的生态环境监管管理体系，建立不同类型生态环境空间监督与绩效考核评价体系。
                    </p>
                    <p>3.4 优化生态环境市场经济机制</p>
                    <p>
                      生态环境市场机制需要进一步调整和优化，进一步提升市场经济政策在生态环境政策体系中的地位和作用，更加注重经济政策的完善、政策手段组合优化调控，增进政策调控功能和实施效能。
                    </p>
                    <p>
                      一是完善生态环境财政制度。健全生态环境财政预算支出制度，改革节能环保财政账户，全面建立生态环境质量改善绩效导向的财政资金分配机制，补贴从生产端为主逐步调整到消费端为主，补贴方向调整为针对生态环境技术创新应用。
                    </p>
                    <p>
                      二是深化绿色税费价格政策机制改革。完善环境保护税、绿色税收优惠、资源税等生态环境保护相关税政策，调整环境保护税征收调控范围，推进将生态环境外部成本纳入资源税改革；完善环境基础设施公共服务供给收费政策，推动建立全成本覆盖的污水处理费政策，建立有利于促进垃圾分类和减量化、资源化、无害化处理的税费激励机制，研究建立健全覆盖成本并合理盈利的固体废物处理收费机制。
                    </p>
                    <p>
                      三是深化生态补偿制度改革。统筹各领域、各要素生态补偿，推进实施综合生态补偿；完善国家重点生态功能区转移支付机制，继续推进跨省界流域上下游生态补偿机制建设，研究建立海洋生态环境损害赔偿责任制度，推进形成市场化、多元化生态环境补偿机制。
                    </p>
                    <p>
                      四是健全生态环境权益市场交易机制。在全国范围内推开碳交易市场，继续推动排污权交易、资源权益交易，建立健全归属清晰、权责明确、流转顺畅、保护严格、监管有效的自然资源产权制度。五是完善绿色金融体系。引导和鼓励长江等重点流域以及粤港澳大湾区等重点区域探索设立绿色发展基金，健全绿色资本市场，完善环境信用评价和上市公司环境信息披露制度。
                    </p>

                    <p>3.5 建立健全生态环境治理格局</p>
                    <p>
                      生态环境治理需要充分发挥各方治理主体的积极性自觉性、能动性，各就其位、各司其职，最大程度动员各相关方力量，既要落实责任，也要保障权益；既要加大违规惩戒，也要加强正向奖惩激励。
                    </p>
                    <p>
                      一是完善生态环境保护督查制度体系。推进生态环境督查制度化、规范化、精简化，形成中央生态环境保护督查、部门生态环境保护专项督查、省级政府环境监察体系合理分工、高效协作的督查制度，强化对地方党委政府履责的监督力度，以督查落实“党政同责”和“一岗双责”。
                    </p>
                    <p>
                      二是建立排污许可证管理政策体系。建立基于环境容量管理的排污许可制，以排污许可证为核心，加快整合点源管理手段，强化许可证实施监管，完善排污许可证管理技术体系。
                    </p>
                    <p>
                      三是提升各级党委政府生态环境治理能力。强化各级党委在生态文明建设和生态环境保护中的领导作用，推进开展省级及以下生态文明建设绩效考核机制。
                    </p>
                    <p>
                      四是推动形成美丽中国建设全民行动体系。提升全民参与环境治理意识和能力，形成党委领导、政府主导、企业实施、社会参与的大生态环境治理格局。建立与完善人民环保监督员制度。
                    </p>
                    <p>
                      五是充分发挥人大政协、“两法”治理功能。积极推动法律监督与公众监督、舆论监督相结合，将政协生态环境治理监督纳入政治协商和民主监督范围，完善落实和反馈机制。健全检察机关提起公益诉讼制度，与环境执法机关共同推进环境治理。
                    </p>
                    <p>3.6 积极推动全球生态环境治理合作</p>
                    <p>
                      “十四五”时期，国际形势复杂多变，充满不确定性，需要履行好国际生态环境责任，积极通过深化全球生态环境合作，提供更多全球公共物品，打造人类命运共同体，为人类可持续发展事业做出新贡献。
                    </p>
                    <p>
                      一是推动落实2030年可持续发展议程。动态跟踪评估《2030年可持续发展议程》中生态环境目标指标进展，定期发布《中国落实2030年可持续发展议程进展报告》。
                    </p>
                    <p>
                      二是积极参与全球生态环境治理。创新多边合作思路，加强与联合国环境规划署、联合国开发署、联合国工业发展组织等国际组织和机构的合作伙伴关系，积极参与亚太经合组织、金砖国家等合作机制框架下环保领域交流合作。
                    </p>
                    <p>
                      三是深化“一带一路”生态环境领域合作，强化绿色“一带一路”建设与共建国家和地区可持续发展目标与战略政策的协调，推动《“一带一路”绿色投资指引》编制实施，积极推进绿色发展和生态环境保护标准国际互认。
                    </p>
                    <p>
                      四是推进绿色贸易与绿色责任投资。加强对环境措施的贸易合规性审查，推动降低、取消重污染行业产品的出口退税、适度提高出口量较大的“两高一资”行业的环境标准，推动发挥环境保护作用促进供给侧结构性改革，推进可持续生产与消费及绿色供应链国际合作。
                    </p>
                    <p>4. 强化生态环境政策改革配套措施</p>
                    <p>4.1 建立严密的生态环境法治体系</p>
                    <p>
                      生态环境法治需要一以贯之。“十四五”时期，需要进一步完善生态环境法律法规体系，更加强调区域流域特征性，更加强调地方差异性，积极推进区域流域立法和标准技术工作，鼓励指导地方因地制宜开展相关立法和标准制定工作。
                    </p>
                    <p>
                      一是继续推进生态环境法律制定实施。制定和完善排污许可、环境监测管理、生态保护红线等方面的法律法规。研究论证环境责任、环境应急管理、环境污染责任保险、环境规划等法规。
                    </p>
                    <p>
                      二是加强流域区域环境标准制定和实施。加快制定实施流域污染排放标准和技术导则，鼓励地方探索制定流域型水污染物排放标准，构建符合我国国情的水质基准制定方法学研究体系，鼓励生态环境质量较好地区探索制定更高要求的标准，完善配套监测方法标准。
                    </p>
                    <p>
                      三是加强对地方环境法律法规标准的指导和规范。积极支持和推动地方制定地方环保法规或者规章，突出地方特色，鼓励地方在生态环境保护与治理领域先于国家进行立法。指导地方建立与辖区生态环境承载能力相适应的标准体系。
                    </p>
                    <p>
                      四是积极推进生态环境司法体系建设。完善行政执法和环境司法的衔接机制，完善生态环境审判机制和程序，完善重大环境资源案件管辖制度。
                    </p>
                    <p>
                      五是推进生态环境损害赔偿制度和公民环境诉权的司法保障。推动环境损害鉴定评估工作，加快编制《生态环境损害鉴定评估技术指南--污染物性质鉴别》《生态环境损害鉴定评估技术指南--替代等值分析法》等技术方法，建立环境公益诉讼专项基金。
                    </p>
                    <p>4.2 推进统一生态环境监管执法</p>
                    <p>
                      “十四五”时期，统一生态环境监管执法需要强化落地，强化统一实施、强化能力建设，进一步提升生态环境监管效果、效率和效能。
                    </p>
                    <p>
                      一是强化区域流域海域监管，加强自然保护地监管。抓好入河排污口设置审查监督、排海污染物总量控制和陆源污染物排海监督，强化对围填海、海洋油气勘探开发、海洋倾废、海洋工程建设项目生态环境影响的监督管理。建立常态化自然保护地监督检查机制，完善地方自然保护地生态环境监管体系。
                    </p>
                    <p>
                      二是推进实现统一生态环境保护执法。整合不同领域、不同部门的监管力量，构建环境保护综合执法体制，整合组建生态环境保护综合执法队伍，完善跨区域、流域、海域的环境监管机制建设，尤其是强化区域生态环境执法协同力度。
                    </p>
                    <p>
                      三是强化基层生态环境执法能力建设。建立健全城市综合执法部门与环境执法部门间信息共享、证据共享机制和联动机制，形成执法合力。充分运用科技手段，提高环境监管执法精准度，推广非现场监管执法模式，探索“智慧执法”新模式。
                    </p>
                    <p>
                      四是构建智能生态环境监测网络体系。综合应用无人机、卫星遥感、航空遥感、全球定位系统等各种监测手段，构建一套动态、立体、全方位、多层次、全覆盖的智能环境监测网络体系。创新“互联网+监管”应用模式，构建环境监管大数据平台。健全监测数据质量控制与科学统计制度。建立高效科学的生态环境监测数据采集机制和能力。
                    </p>
                    <p>4.3 强化生态环境政策执行能力</p>
                    <p>
                      生态环境政策执行一直是生态决策政策链的短板，这是“十四五”时期生态环境政策改革需要着力突破的政策关节。
                    </p>
                    <p>
                      一是建立生态环境政策评估机制。探索重大环境政策第三方机制，建立政策评估的专家技术能力，研究生态环境政策评估结果反馈机制与重大政策适时修订机制，及时发现问题并提出改进措施。探索多元评估主体有序参与机制，开展第三方专业评估，提高生态环境决策科学化和民主化。
                    </p>
                    <p>
                      二是是建立生态环境保护重大政策评估技术体系。研究基于费用—效益等分析方法的生态环境政策评估技术，建立重大政策制定和实施的费用效益及经济社会影响评估技术指南。
                    </p>
                    <p>4.4 夯实科技创新支撑与保障</p>
                    <p>
                      政策的执行需要科技保障，通过科技创新提升生态环境政策实施效能是“十四五”政策工作的重点内容。“十四五”时期，生态环境政策制定和实施需要充分利用好科技方法和手段，提升政策制定的科学水平，政策执行的成效和效率。
                    </p>
                    <p>
                      一是加强生态环境保护科学决策与实施能力。对生态环境政策制定的关键领域和技术问题进行系统研究，推进生态环境政策制定新技术和新方法研究，构建科学制定生态环境政策的技术体系。
                    </p>
                    <p>
                      二是促进和鼓励物联网、大数据、云计算、人工智能、卫星遥感等高科技技术手段在政策制定领域的创新推广应用。
                    </p>
                    <p>
                      三是强化科技创新的资金保障。按照事权与支出责任相适应的原则，各级财政对生态环境政策制定实施予以合理保障。深化科技计划管理改革，整合优化资源，支持政策制定重点领域科技研发和示范应用，促进生态环境政策制定技术创新。
                    </p>
                    <p>
                      5. “十四五”生态环境政策改革创新路径
                      本研究系统分析了生态环境政策实施现状、改革基础与“十四五”时期改革需求，提出了“十四五”生态环境政策改革创新路径。“十四五”时期主要针对绿色发展四大结构调整、生态环境空间管控、生态环境质量目标管理、生态环境市场经济政策、生态环境治理体系、全球环境治理六大环境政策领域进行改革路径创新，建立三档次、四类别、两阶段政策改革逻辑框架。其中，政策执行水平分为良好、一般与较差三个档次，政策调整类型分为新立、修改、保持、废除四个类别，政策路径分为2021—2023年、2024—2025年两个阶段。总体上，“十三五”时期工业行业超低排放补贴、清洁取暖补贴、绿色税费价格、生态环境治理、环境全球治理等多数政策执行水平良好，“十四五”时期加强政策的稳定性和连续性，完善并有序推进政策实施。差别电价水价双调控、轻型车超低排放、岸电使用补贴等政策执行水平一般，需要进一步完善与重点提升。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="news-class">
              <h4>推荐新闻</h4>
              <ul>
                <li>
                  <router-link :to="{path:'newsDetail',query:{id:1002}}">
                    年度上新！苏州人才政策申报月历（2024版）来啦→
                  </router-link>
                </li>
                <li>
                  <router-link :to="{path:'newsDetail',query:{id:1001}}">
                    苏州腾康签约中国-沙特投资大会
                  </router-link>
                </li>
                <li>
                <router-link :to="{path:'newsDetail',query:{id:0}}">
                  苏州腾康环保科技有限公司作为有机垃圾处理行业的领跑者，应邀参加第24届中国环博会。
                </router-link>
              </li>
                <li>
                <router-link :to="{path:'newsDetail',query:{id:1}}">
                    苏州腾康环保科技有限公司受邀参加赣州市厨余垃圾资源化利用培训交流会
                </router-link>
              </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:2}}">
                    热烈祝贺我司荣获中国城市环境卫生协会科学技术奖科技进步一等奖
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:3}}"
                    >2022年开工动员大会</router-link
                  >
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:4}}"
                    >喜报！我司共同参与研究的论文在著名Wiley期刊成功发表</router-link
                  >
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:5}}"
                    >苏州腾康环保科技有限公司完美亮相第22届中国环博会</router-link
                  >
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:7}}"
                    >关于做好2020年畜禽粪污资源化利用工作的通知</router-link
                  >
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:11}}"
                    >国家“十四五”生态环境政策改革重点与创新路径研究</router-link
                  >
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:10}}"
                    >RCR新文：植物油和动物脂肪生产可再生柴油对全球暖化潜势的影响</router-link
                  >
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:6}}"
                    >农村生活垃圾分类减量资源化模式及其影响因素与管理对策
                    ——以杭州市为例</router-link
                  >
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:9}}"
                    >北京、深圳5月1日起开始垃圾分类
                    谁是下一个风口？</router-link
                  >
                </li>
                <li>
                  <router-link :to="{name:'newsDetail',query:{id:8}}"
                    >修订后的固体废物污染环境防治法
                    自2020年9月1日起施行</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
    };
  },

watch: {
	// 监听路由是否变化
	'$route' (to, from) {
		if(to.query.id != from.query.id){
		    this.id = to.query.id; // 把最新id赋值给定义在data中的id
			this.init(); // 重新调用加载数据方法
		}
	}
},


  created() {
    setTimeout(()=>{
      this.id = this.$route.query.id;
    })
  },
  mounted(){
    this.id = this.$route.query.id;
  }
};
</script>
<style scoped>
.news-main {
  width: 100%;
  overflow: hidden;
  background: #fff;
  padding: 5px 0 20px 0;
}

.left-nav {
  float: left;
  margin-top: 30px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #000;
}

.news-class {
  width: 23%;
  background: #fff;
  float: left;
  overflow: hidden;
  margin-left: 20px;
}

.news-class h4 {
  font-size: 22px;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
}

.news-class ul li a {
  width: 94%;
  margin: auto;
  display: block;
  overflow: hidden;
  padding: 10px 0 10px 20px;
  font-size: 16px;
  color: #0e6eb8;
}

ul li::marker {
  color: rgb(0, 0, 0);
  font-size: 30px;
}

.synopsis-img {
  width: 100%;
}

.synopsis-img img {
  max-width: 100%;
  display: block;
  border: 0;
  margin: auto;
}

.synopsis-text {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
}


.synopsis-text h1 span {
  font-weight: bold;
  font-size: 26px;
  color: #0e6eb8;
}

.synopsis-text p {
  line-height: 22px;
  font-size: 15px;
  text-indent: 2em;
  text-align: left;
}

.section .text-box {
  width: 74%;
  float: left;
}

.back-page {
  background: #fff;
}

.synopsis {
  text-align: center;
  width: 100%;
}

.synopsis i {
  display: block;
  width: 2px;
  height: 32px;
  margin: auto;
  background: #b5b5b5;
}

.synopsis h2 {
  font-size: 31px;
  color: #3b3b3b;
  padding: 5px 0;
  padding-bottom: 0px;
}

.synopsis-text h3 {
  color: red;
  text-align: left;
  padding: 5px 0;
  text-indent: 2em;
}

.synopsis-page {
  overflow: hidden;
  min-width: 1250px;
  height: 100%;
  margin: auto;
  padding: 0;
  background: #fff;
}


.synopsis-text h4 {
  font-size: 18px;
  font-weight: 800;
  color: rgb(0, 0, 0);
  padding: 10px 0;
  text-indent: 2em;
  text-align: left;
}

.synopsis-text h5 {
  font-size: 14px;
  color: #999;
  padding: 10px 0;
}



.plan-text {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
  line-height: 35px;
  font-size: 15px;
  text-indent: 2em;
  text-align: left;
}

.plan-img img {
  width: 1200px;
  margin: auto;
  text-align: center;
  background-size: 100% 100%;
}

.plan-img img :hover {
  background-size: 110% 110%;
}

.section {
  width: 1300px;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: auto;
}

.synopsis em {
  display: block;
  width: 37px;
  height: 4px;
  margin: auto;
  background: #76c817;
}

.synopsis p {
  color: #6c6c6c;
  font-size: 14px;
  padding: 5px 0;
}
</style>

